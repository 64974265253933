import React, { useState, useEffect } from "react";
import { styled, Paper, Box, Divider, Container, Typography, Skeleton, Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid } from '@mui/material';
import { useLocation, useNavigate } from "react-router-dom";
import { ChevronRight, ArrowBack} from '@mui/icons-material';
import MainBg  from '../../components/MainBg';
import { Masonry } from '@mui/lab';
import axios from "axios";
import { Image } from 'primereact/image';
import { Helmet } from 'react-helmet';

const TitleTexts = styled(Typography)(({ theme }) => ({
  '&': {
    color: '#fe7b00',
    fontWeight: 800,
    fontSize:22
  },
})) as typeof Typography
const NormalTexts = styled(Typography)(({ theme }) => ({
  '&': {
    fontSize:20,
    color: '#000',
    fontFamily:"Century Gothic,CenturyGothic,AppleGothic,sans-serif"
  },
})) as typeof Typography
const SubTitleTexts = styled(Typography)(({ theme }) => ({
  '&': {
    color: '#436885',
    fontWeight: 800,
    fontSize:22
  },
})) as typeof Typography

const prefUrl = 'https://www.german-emirates-club.com/uploads/sys/';

const EventGalleryAlbum: React.FC = (props) => {

const navigate = useNavigate();
const locationState = useLocation().state as {id: any}; 
const catId = locationState.id;

const [images, setImages] = useState([]);
const [album, setAlbum] = useState([]);

const prefUrl = 'https://www.german-emirates-club.com/uploads/sys/';

useEffect(() => {
  const fetchEventsGallery = async () => {
    const res = await axios.get(`https://staging.german-emirates-club.com/admin/node/api/events/gallery-album/${catId}`);
    const allImages = res.data.data.map((image) => image["file"]);
    setImages(allImages);
    if(res.data.data[0].catName) {
      setAlbum(res.data.data[0].catName);
    }
   
  };
  fetchEventsGallery();
},[])

return (
  <Box position="relative" sx={{ 
    backgroundColor: 'rgba(255, 255, 255, 0.32)',
    minHeight:'calc(100vh - 150px) ',
  }}>
    <Container sx={{ mX:0, pX:0, py: 4, maxWidth:1247, position: "relative" }} >
      <Paper sx={{ p: 5}} elevation={20} >
          
        
        <Grid container spacing={1} columns={{ xs: 12 }}  mb={2} >
          <Grid item xs={8} > <TitleTexts mb={2} >GEC <span style={{ color:'#001b39'}}>Mitgliedergalerie</span></TitleTexts> </Grid>
          <Grid item xs={4} justifyContent='flex-end' justifyItems='flex-end' sx={{ textAlign:'right'}} alignItems='center'>
              <Typography  pt={1} fontSize={20}> 
              <a onClick={ () => { navigate('/Events/Gallery')}} > <ArrowBack sx={{height:'20px', paddingTop:'4px'}} /> Zurück zur Übersicht </a></Typography> </Grid>
        </Grid>

        <Divider sx={{mb:3}} />

        <SubTitleTexts mb={{xs:1,sm:2,md:3}}>{album}</SubTitleTexts> 

        <Box sx={{ }}>
          <Masonry columns={3} spacing={2}>

            {
              images.length ?
              images.map((item:any, index) => (
                <div key={index}>
                  <Image
                    src={`${prefUrl}${item}`} //?w=162&auto=format`}
                    style={{
                      borderBottomLeftRadius: 4,
                      borderBottomRightRadius: 4,
                      display: 'block',
                    }}
                    className="w-full"
                    imageClassName="w-full"
                    preview
                  />
                </div>
              ))
              : 
              Array.from(new Array(20)).map((i) => {
               return ( <div key={i}><Skeleton variant="rectangular" height={Math.floor(Math.random() * 100) + 300}  width={380}/> </div>  )
              })
            }

          </Masonry>

        </Box>  

      </Paper>
    </Container>

    <Helmet>
      <title>German Emirates Club | Gallerie</title>
      <meta name="Title" content="German Emirates Club | Gallerie"/>
      <meta name="robots" content="index, follow"/>
    </Helmet>

  </Box>
)
}

export default EventGalleryAlbum;
