import React, { useState, useEffect } from "react";
import { styled, Paper, Box, Container, Grid, Typography, List, ListItem, Divider, ListItemText, Toolbar, ListItemButton, ListItemIcon } from '@mui/material';
import { useLocation, useNavigate } from "react-router-dom";
import { ChevronRight, ArrowBack} from '@mui/icons-material';
import MainBg  from '../../components/MainBg';
import axios from "axios";

const TitleTexts = styled(Typography)(({ theme }) => ({
  '&': {
    
    color: '#fe7b00',
    fontWeight: 500,
    fontSize:24
  },
})) as typeof Typography
const NormalTexts = styled(Typography)(({ theme }) => ({
  '&': {
    color: '#001b39',
    textAlign: "justify",
  },
})) as typeof Typography
const ListTexts = styled(Typography)(({ theme }) => ({
  '&': {
    color: '#436885',
  },
})) as typeof Typography
const LinkStyled =  {
  color: '#436885',
  fontWeight: 500,
  display: 'inline'
}

///partners/partner-tags:id

const prefUrl = 'https://www.german-emirates-club.com/uploads/sys/';

const PartnerProfile: React.FC = (props) => {
const navigate = useNavigate();
const locationState = useLocation().state as {partner: any}; 
const partnerProfile = locationState.partner;
const partPhoto = prefUrl + partnerProfile.file;
console.log(partnerProfile);

const [partnerTags, setPartnerTags] = useState('');
const fetchPartnerTags =async () => {
  const res = await axios.get(`https://staging.german-emirates-club.com/admin/node/api/partners/partner-tags/${partnerProfile.id}`);
  if(res.data.data.length){
    const tagArr = res.data.data.map(a =>a.tag);
    setPartnerTags(tagArr.join(', '));
  }
}
useEffect(()=> {
  fetchPartnerTags();
},[])

return (
  <Box position="relative" sx={{ 
    backgroundColor: 'rgba(255, 255, 255, 0.32)',
    minHeight:'calc(100vh - 150px) ',
  }}>
      <Container sx={{ mX:0, pX:0, py: 4, maxWidth:1247, position: "relative" }} >
        <Paper sx={{ p: 5}} elevation={20} >

          <Grid container spacing={4} columns={{ xs: 4, sm:12, md: 12 }}>
       
              <Grid item xs={4} sm={6} md={9} >
                <Box>
                  <Grid container spacing={1} columns={{ xs: 12 }}  mb={2} >
                    <Grid item xs={8} > <TitleTexts > {partnerProfile.title} </TitleTexts> </Grid>
                    <Grid item xs={4} justifyContent='flex-end' justifyItems='flex-end' sx={{ textAlign:'right'}} alignItems='center'>
                       <Typography  pt={1} fontSize={20}> 
                        <a onClick={ () => { navigate('/Partner')}} > <ArrowBack sx={{height:'20px', paddingTop:'4px'}} /> Zurück zur Übersicht </a></Typography> </Grid>
                  </Grid>
                    
                  <Divider sx={{mb:3}} />

                  <List sx={{ width: '100%'}} >
                    <ListItem disablePadding>
                      <ListItemButton>
                        <ListItemIcon> <ChevronRight /> </ListItemIcon>
                        <ListItemText primary={  <ListTexts> Dieser Partner gibt den Mitgliedern des German Emirates Club Nachlässe. Die Nachlässe sind ausschließlich den Clubmitgliedern ersichtlich. </ListTexts>  } />
                      </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                      <ListItemButton>
                        <ListItemIcon> <ChevronRight /> </ListItemIcon>
                        <ListItemText primary={  <ListTexts> {partnerTags} </ListTexts>  } />
                      </ListItemButton>
                    </ListItem>

                    
                  </List>
             
                </Box>
              </Grid>

              <Grid item xs={4} sm={6} md={3} display={'flex'} justifyContent='center' alignContent={'center'} > 
                <Box component='img' src={partPhoto} maxHeight={'100%'} maxWidth={'100%'} sx={{objectFit:'contain'}} border={'1px solid #ccc'} />
              </Grid>

            </Grid>

            <List sx={{ width: '100%'}} >
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon> <ChevronRight /> </ListItemIcon>
                  <ListItemText primary={  <ListTexts> {partnerProfile.content}</ListTexts>  } />
                </ListItemButton>
              </ListItem>
            </List>

        </Paper>
      </Container>
  </Box>
)
}

export default PartnerProfile;
