import axios from "axios";
import React, { useState, useEffect } from "react";
import { styled, Button, FormControlLabel, Paper, Typography, Checkbox, Grid, TextField, Box, Container, Stack } from '@mui/material';
import { loginFailure, loginStart, loginSuccess } from "../../redux/userSlice";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import GECBackground from '../../assets/GECBackground.png'
import {Cookies} from 'react-cookie';
import Reset from "../../pages/Reset/Reset.lazy";
import GECBackgroundLowres from '../../assets/GECBackgroundLowres.jpg'

const fontFam = 'Century Gothic,CenturyGothic,AppleGothic,sans-serif';
const NormalTexts = styled(Typography)(({ theme }) => ({
  '&': {
    fontFamily:{fontFam}
  },
})) as typeof Typography

const HomeLogin=()=>{

    const avatarStyle={
      backgroundColor:'inheret'
    }
    
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const formDefault = {
      value: "",
      valid: false,
      error: ""
    };
    const [login, setLogin] = useState({
      username: formDefault,
      password: formDefault,
      bookmark: formDefault,
    });
    
    const handleChange = (e : any) => {
      const isError = !e.target.value;
      setLogin(prev => ({ ...prev, [e.target.name]: {
        value: e.target.value,
        valid: isError,
        error: isError ? 'Erforderlich' : ""
      }}));
    }
    const [formError, setFormError] = useState('');
    const [formErrorStatus, setFormErrorStatus] = useState(0);
    const handleLogin = async (e : any) => {
      try {
        e.preventDefault();
        setFormError('');
        setFormErrorStatus(0);
        // validate inputs first
        let hasError = false;
        Object.keys(login).forEach(field => {
          let isError = !login[field].value && field != 'bookmark';
          let errMsg = isError ? 'Bitte füllen Sie dieses Feld aus.' : '';
          if(errMsg != '') hasError = true;
          setLogin(prev => ({ ...prev, [field]: { ...login[field], valid: isError, error: errMsg } }));
        });
        if(hasError) return;

        //dispatch(loginStart());
        const reqData = {
          username: login.username.value,
          password: login.password.value,
          bookmark: login.bookmark.value
        }
        console.log('reqData', reqData);
        const res = await axios.post('login.php', reqData); //https://staging.german-emirates-club.com/admin/node/api/auth/login 
        if(res.data.error != null && res.data.error > 0) {
          setFormError('');
          if(res.data.error == 1) setFormErrorStatus(res.data.satus);
        }
        if(res.data.error == null ) {
          //dispatch(loginSuccess(res.data.results));
          //navigate('/');
          if(res.data.cookie) {
            const cookieObj = res.data.cookie;
            const cookies = new Cookies();
            for (const key in cookieObj) {
              const values = cookieObj[key];
              cookies.set(key, values[0], { maxAge: values[1],path: values[2] });
            }
          }
          window.location.replace('/');
        } else if(res.data.error) {
          //dispatch(loginFailure());
          setFormError(res.data.error);
        } else {
          window.location.replace('/');
        }
      } catch (error) {
        console.log(error)
        setFormError('Etwas ist schief gelaufen. Bitte laden Sie die Seite neu und versuchen Sie es erneut.');
        //dispatch(loginFailure());
      }
    }

    const [bgSource, setSource] = useState(GECBackgroundLowres);
    useEffect(() => {
      const img = new Image()
      img.src = GECBackground
      img.onload = () => setSource(GECBackground)
    }, [GECBackground])

    return(
      <Box sx={{ 
        flexGrow: 1, 
        backgroundImage: `url(${bgSource})`, 
        height:'calc(100vh - 150px) ', 
        backgroundSize: 'cover',
        bgColor:'#fffbd8'
      }}>
        <Container sx={{maxWidth:1247}} >
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={1}
          >
            <Box sx={{ display: { xs: 'none', md: 'flex' } }} flex={1}></Box>
            <Box flex={1} justifyContent="flex-end" alignItems="center" mr={5}>
              <Paper elevation={20}   
              sx={{
                  p:4,
                  maxWidth:350,
                  mx: "auto",
                  mt:'calc(50vh - 325px) ',
              }} >
                  <NormalTexts fontSize={'30px'} mb={0} color={'#707070'}>Einloggen</NormalTexts>
                  <NormalTexts fontSize={'16px'} mb={3} color={'#707070'}>mit Ihrem Nutzernamen und Passwort</NormalTexts>
                  <Grid container direction={"column"} spacing={1}>
                    <Grid item>
                      <TextField 
                        sx={{ fontSize: 16, p:0, m:0, fontFamily:{fontFam} }}
                        name="username"
                        onChange={handleChange} 
                        label='Nutzername' 
                        placeholder='Benutzernamen eingeben' 
                        variant="outlined" 
                        helperText={login.password.error}
                        error={login.username.valid}
                        fullWidth required/>
                    </Grid>
                    <Grid item>
                      <TextField 
                        sx={{ fontSize: 16, p:0, m:0, mt:'24px', fontFamily:{fontFam} }}
                        name="password"
                        onChange={handleChange} 
                        label='Passwort' 
                        placeholder='Passwort eingeben' 
                        type='password' 
                        variant="outlined" 
                        helperText={login.password.error}
                        error={login.password.valid}
                        fullWidth 
                        required/>
                    </Grid>
                  </Grid>
                  <Grid>
                      <FormControlLabel control={ <Checkbox name='bookmark' /> }  label="Speichern"  style={{fontSize:12}} onChange={handleChange}  />
                  </Grid>
                  <Grid
                  container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center">
                    <Button onClick={handleLogin} variant="contained" sx={{
                      background: 'linear-gradient(270deg, rgba(0,29,59,1) 0%, rgba(9,79,117,1) 35%, rgba(18,128,173,1) 100%)',
                      borderRadius: 8,
                      px: 6,
                      textTransform: 'none',
                      mb:1
                    }}>Einloggen</Button>
                    <NormalTexts >
                          <Link to="/Reset" style={{textDecoration: 'none', color: "#436885", fontSize: 12 }}>
                            Logindaten vergessen?
                        </Link>
                    </NormalTexts>
                    {/* <NormalTexts fontSize={14}> Noch kein Mitglied?   <Link to="/" style={{color: "#436885"  }} > Klicke hier!  </Link></NormalTexts> */}
                    { formError ? <Typography fontSize={15} ml={'35px'} color={'#FF0000'} display={'inline-block'} fontFamily={fontFam}>{formError}</Typography> : ''}
                    { formErrorStatus == 2 ? <Typography fontSize={15} ml={'35px'} mb={3} color={'#FF0000'} display={'inline-block'} fontFamily={fontFam}>
                      Profil wurde vorrübergehend gesperrt. Bitte wenden Sie sich an den <Link to="/Contact">Support</Link>
                    </Typography> : ''}
                    { formErrorStatus == 3? <Typography fontSize={15} ml={'35px'}  color={'#FF0000'} display={'inline-block'} fontFamily={fontFam}>
                    Ihr Profil wurde deaktiviert, da Ihr letzter Login zu weit in der Vergangenheit liegt. Bitte fordern Sie <Link to="/Reset">hier</Link> ein neues Passwort an. Sollte dieses Problem weiterhin bestehen, wenden Sie sich an den <Link to="/Contact">Support</Link>
                    </Typography> : ''}
                    { formErrorStatus == 99 ? <Typography fontSize={15} ml={'35px'} color={'#FF0000'} display={'inline-block'} fontFamily={fontFam}>
                    Benutzername und/oder Passwort sind falsch. Wenn Sie Ihre Logindaten vergessen haben, klicken Sie <Link to="/Reset">hier</Link>.
                    </Typography> : ''}
                  </Grid>
              </Paper>
            </Box>
          </Stack>
        </Container>
      </Box>
    )
}

export default HomeLogin