import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from 'react-redux';
import { alpha, styled,InputBase, Paper, Box, Container, Grid, Typography, List, ListItem, Divider, ListItemText, ListItemButton, ListItemIcon, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent, Button, OutlinedInput, useTheme, Theme, Toolbar, FormControlLabel, Checkbox, TextField } from '@mui/material';
import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ChevronRight} from '@mui/icons-material';
import { Helmet } from 'react-helmet';
import axios from "axios";
import {Warning} from '@mui/icons-material';
import {Cookies} from 'react-cookie';

const TitleTexts = styled(Typography)(({ theme }) => ({
  '&': {
    color: '#fe7b00',
    fontWeight: 800,
    fontSize:22,
    fontFamily:"Century Gothic,CenturyGothic,AppleGothic,sans-serif"
  },
})) as typeof Typography
const NormalTexts = styled(Typography)(({ theme }) => ({
  '&': {
    fontSize:20,
    color: '#000',
    fontFamily:"Century Gothic,CenturyGothic,AppleGothic,sans-serif"
  },
})) as typeof Typography
const ListTexts = styled(Typography)(({ theme }) => ({
  '&': {
    fontSize:20,
    color: '#436885',
    fontFamily:"Century Gothic,CenturyGothic,AppleGothic,sans-serif"
  },
})) as typeof Typography
const LinkStyled =  {
  fontSize:20,
  color: '#436885',
  fontWeight: 500,
  display: 'inline',
  fontFamily:"Century Gothic,CenturyGothic,AppleGothic,sans-serif",
}
const SInputLabel = styled(InputLabel)(({ theme }) => ({
  '&': {
    color: '#436885',
    fontSize:20,
    fontFamily:"Century Gothic,CenturyGothic,AppleGothic,sans-serif"
  },
})) as typeof InputLabel

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
    border: '1px solid #ced4da',
    fontSize: 16,
    width: '100%',
    padding: '10px 12px',
    marginBottom: theme.spacing(3),
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    fontFamily:"Century Gothic,CenturyGothic,AppleGothic,sans-serif",
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));



const BoxNames = {
  'Login':8,
  'Management':13,
  'Mitgliedschaft':10,
  'Presse':5,
  'Webmaster':9
}

const fontFam = 'Century Gothic,CenturyGothic,AppleGothic,sans-serif';


const Login  = () => {

  const search = useLocation().search;
  const op = new URLSearchParams(search).get('op');
  const [formError, setFormError] = useState('Sie sind nicht angemeldet, bitte loggen Sie sich ein.');
  const [formErrorStatus, setFormErrorStatus] = useState(0);

  if(op == 'logout'){
    setFormError('Sie haben sich erfolgreich ausgeloggt.');
  }

  const dispatch = useDispatch();
    const navigate = useNavigate();

    const formDefault = {
      value: "",
      valid: false,
      error: ""
    };
    const [login, setLogin] = useState({
      username: formDefault,
      password: formDefault,
      bookmark: formDefault,
    });
    
    const handleChange = (e : any) => {
      const isError = !e.target.value;
      setLogin(prev => ({ ...prev, [e.target.name]: {
        value: e.target.value,
        valid: isError,
        error: isError ? 'Bitte füllen Sie dieses Feld aus.' : ""
      }}));
    }
    
    const handleLogin = async (e : any) => {
      try {
        e.preventDefault();
        setFormError('');
        setFormErrorStatus(0);

        // validate inputs first
        let hasError = false;
        Object.keys(login).forEach(field => {
          let isError = !login[field].value && field != 'bookmark';
          let errMsg = isError ? 'Bitte füllen Sie dieses Feld aus.' : '';
          if(errMsg != '') hasError = true;
          setLogin(prev => ({ ...prev, [field]: { ...login[field], valid: isError, error: errMsg } }));
        });
        if(hasError) return;

      //dispatch(loginStart());
        const reqData = {
          username: login.username.value,
          password: login.password.value,
          bookmark: login.bookmark.value
        }
        console.log('reqData', reqData);
        const res = await axios.post('login.php', reqData); //https://staging.german-emirates-club.com/admin/node/api/auth/login 
        if(res.data.error != null && res.data.error > 0) {
          setFormError('');
          if(res.data.error == 1) setFormErrorStatus(res.data.satus);
        }
        if(res.data.error == null ) {
          //dispatch(loginSuccess(res.data.results));
          //navigate('/');
          if(res.data.cookie) {
            const cookieObj = res.data.cookie;
            const cookies = new Cookies();
            for (const key in cookieObj) {
              const values = cookieObj[key];
              cookies.set(key, values[0], { maxAge: values[1],path: values[2] });
            }
          }
          window.location.replace('/');
        } else if(res.data.error) {
          //dispatch(loginFailure());
          setFormError(res.data.error);
        } else {
          window.location.replace('/');
        }
      } catch (error) {
        console.log(error)
        setFormError('Etwas ist schief gelaufen. Bitte laden Sie die Seite neu und versuchen Sie es erneut.');
        //dispatch(loginFailure());
      }
    }


  return (
    <Box position="relative" sx={{ 
      backgroundColor: 'rgba(255, 255, 255, 0.32)',
      minHeight:'calc(100vh - 150px) ',
    }}>
        <Container sx={{ mX:0, pX:0, py: 4, maxWidth:1247, position: "relative" }} >
          <Paper sx={{ p: 5}} elevation={20} >

            <TitleTexts mb={2} ><span style={{ color:'#001b39'}}>Login für Mitglieder</span></TitleTexts>
            <Divider sx={{mb:3}} />

            <Grid container spacing={2} columns={{ xs: 4, sm:12, md: 12 }}>
        

                <Grid item xs={4} sm={5} md={5} >

                  <NormalTexts fontSize={'16px'} mb={3} color={'#707070'}>mit Ihrem Nutzernamen und Passwort</NormalTexts>

                  <Box display={'relative'} pb={3}>
                    <Warning sx={{ position:'absolute' }}  />
                    { formError ? <Typography fontSize={15} ml={'35px'} color={'#FF0000'} display={'inline-block'} fontFamily={fontFam}>{formError}</Typography> : ''}
                    { formErrorStatus == 2 ? <Typography fontSize={15} ml={'35px'} mb={3} color={'#FF0000'} display={'inline-block'} fontFamily={fontFam}>
                      Profil wurde vorrübergehend gesperrt. Bitte wenden Sie sich an den <Link to="/Contact">Support</Link>
                    </Typography> : ''}
                    { formErrorStatus == 3? <Typography fontSize={15} ml={'35px'}  color={'#FF0000'} display={'inline-block'} fontFamily={fontFam}>
                    Ihr Profil wurde deaktiviert, da Ihr letzter Login zu weit in der Vergangenheit liegt. Bitte fordern Sie <Link to="/Reset">hier</Link> ein neues Passwort an. Sollte dieses Problem weiterhin bestehen, wenden Sie sich an den <Link to="/Contact">Support</Link>
                    </Typography> : ''}
                    { formErrorStatus == 99 ? <Typography fontSize={15} ml={'35px'} color={'#FF0000'} display={'inline-block'} fontFamily={fontFam}>
                    Benutzername und/oder Passwort sind falsch. Wenn Sie Ihre Logindaten vergessen haben, klicken Sie <Link to="/Reset">hier</Link>.
                    </Typography> : ''}
                  </Box>

                  <Grid container direction={"column"} spacing={1}>
                    <Grid item>
                      <TextField 
                        sx={{ fontSize: 16, p:0, m:0, fontFamily:{fontFam} }}
                        name="username"
                        onChange={handleChange} 
                        label='Nutzername' 
                        placeholder='Benutzernamen eingeben' 
                        variant="outlined" 
                        helperText={login.password.error}
                        error={login.username.valid}
                        fullWidth required/>
                    </Grid>
                    <Grid item>
                      <TextField 
                        sx={{ fontSize: 16, p:0, m:0, mt:'24px', fontFamily:{fontFam} }}
                        name="password"
                        onChange={handleChange} 
                        label='Passwort' 
                        placeholder='Passwort eingeben' 
                        type='password' 
                        variant="outlined" 
                        helperText={login.password.error}
                        error={login.password.valid}
                        fullWidth 
                        required/>
                    </Grid>
                  </Grid>
                  <Grid>
                      <FormControlLabel control={ <Checkbox name='bookmark' /> }  label="Speichern"  style={{fontSize:12}} onChange={handleChange}  />
                  </Grid>
                  <Grid
                  container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center">
                    <Button onClick={handleLogin} variant="contained" sx={{
                      background: 'linear-gradient(270deg, rgba(0,29,59,1) 0%, rgba(9,79,117,1) 35%, rgba(18,128,173,1) 100%)',
                      borderRadius: 8,
                      px: 6,
                      textTransform: 'none',
                      mb:1
                    }}>Einloggen</Button>
                    <NormalTexts >
                          <Link to="/Reset" style={{textDecoration: 'none', color: "#436885", fontSize: 12 }}>
                            Logindaten vergessen?
                        </Link>
                    </NormalTexts>
                    {/* <NormalTexts fontSize={14}> Noch kein Mitglied?   <Link to="/" style={{color: "#436885"  }} > Klicke hier!  </Link></NormalTexts> */}
                    
                  </Grid>
                
                </Grid>
                
                <Grid item xs={4} sm={7} md={7} >

                  <Toolbar sx={{ backgroundColor:'#efefef', minHeight:'unset', px:1, py:0, mt:2}}>
                    <Typography variant="h6" pl={2} fontWeight={600}> Hilfe</Typography>
                  </Toolbar>

                  <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                    <ListItem alignItems="flex-start">
                      <ListItemText
                        secondary={
                          <ListTexts>
                            Wenn Sie Ihren Benutzernamen bisher nicht geändert haben, ist die Emailadresse mit der Sie sich im GEC registriert haben, Ihr Benutzername für den Login.
                          </ListTexts>
                        }
                      />
                    </ListItem>
                    
                    <Divider component="li" />
                    <ListItem alignItems="flex-start">
                      <ListItemText
                        secondary={
                          <ListTexts>
                            Bitte achten Sie auf die korrekte Schreibweise. GROß- und klein-Schreibung wird unterschieden.
                          </ListTexts>
                        }
                      />
                    </ListItem>
                    <Divider component="li" />

                    <Divider component="li" />
                    <ListItem alignItems="flex-start">
                      <ListItemText
                        secondary={
                          <ListTexts>
                            Cookies müssen aktiviert sein.
                          </ListTexts>
                        }
                      />
                    </ListItem>
                    <Divider component="li" />

                    <Divider component="li" />
                    <ListItem alignItems="flex-start">
                      <ListItemText
                        secondary={
                          <ListTexts>
                            Bitte achten Sie auf eventuelle LEERZEICHEN am Ende bzw. am Anfang Ihrer Daten.
                          </ListTexts>
                        }
                      />
                    </ListItem>
                    <Divider component="li" />

                    <Divider component="li" />
                    <ListItem alignItems="flex-start">
                      <ListItemText
                        secondary={
                          <ListTexts>
                            Wenn Sie Ihre Logindaten vergessen haben, klicken Sie <Link style={{color:'#436885'}} to={'/Rreset'} >hier</Link>.
                          </ListTexts>
                        }
                      />
                    </ListItem>
                    <Divider component="li" />

                    <Divider component="li" />
                    <ListItem alignItems="flex-start">
                      <ListItemText
                        secondary={
                          <ListTexts>
                            Um beim nächsten Login automatisch eingeloggt zu werden, müssen Cookies aktiviert sein.
                          </ListTexts>
                        }
                      />
                    </ListItem>
                    <Divider component="li" />

                    <Divider component="li" />
                    <ListItem alignItems="flex-start">
                      <ListItemText
                        secondary={
                          <ListTexts>
                            Wenn Sie noch kein Mitglied im GEC sind, warten Sie bitte auf eine Einladung eines Bekannten oder sprechen Sie jemanden an, der bereits Mitglied im GEC ist.
                          </ListTexts>
                        }
                      />
                    </ListItem>
                    <Divider component="li" />

        
                  </List>
                </Grid>

              </Grid>
          </Paper>
        </Container>

        <Helmet>
          <title>German Emirates Club | Kontakt</title>
          <meta name="Title" content="German Emirates C5lub | Kontakt"/>
          <meta name="robots" content="noindex,follow"/>
        </Helmet>

    </Box>
  )
}

export default Login;
